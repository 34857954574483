$radius: 0.5rem;

.wrapperSlide {
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow:
    0 2px 11px 0 rgb(0 0 0 / 50%),
    inset 0 1px 0 0 rgb(255 255 255 / 62%);
  min-width: 90px;
  min-height: 65px;

  img {
    &:active {
      transform: scale(1.075);
    }
    transition: transform 0.15s ease;
    aspect-ratio: 1.39 / 1;
    object-fit: cover;
    width: 100%;
  }

  img.sliderOverride {
    height: 72px;
    width: 100px;
  }

  &:before {
    pointer-events: none;
    content: '';
    border-radius: $radius;
    border-top: 1px solid rgba(255, 255, 255, 0.62);
    top: 0;
    left: 0;
    width: 100%;
    height: 35%;
    background: linear-gradient(rgba(255, 255, 255, 0.4), transparent);
    position: absolute;
    z-index: 1;
  }

  &.provider {
    background: linear-gradient(0, #ddd, #fff, #fff);
    a {
      width: 100%;
    }

    &:before {
      height: 110%;
    }
  }
}

.thumbnail {
  &__label {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    display: block;
    height: 24px;
    line-height: 24px;
    font-size: 9px;
    font-weight: 600;
    padding: 0 7px;
    border-radius: 0 0 5px 0;
    text-transform: uppercase;

    @media (max-width: 768px) {
      height: 20px;
      line-height: 20px;
    }

    &--new {
      font-size: 14px;
      font-weight: 600;
      left: unset;
      right: 0;
      background: #03a9f4;
      color: white;
      border-radius: 0 0 0 5px;
    }

    &--freespins {
      font-size: 14px;
      font-weight: 600;
      background: #3d943d;
      color: white;
      &.fp-thumbnail__label--hasTournament {
        animation: unset;
        top: 22px;
        border-radius: 0 5px 5px 0;
      }
    }
  }

  &__jackpot {
    display: inline-block;
    line-height: 1.2em;
    padding: 5px 10px 5px 10px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    background: #0284f4;
    border-radius: 0 5px 0 0;
    text-align: center;
    letter-spacing: 0.02rem;

    .fp-thumbnail--listItem & {
      display: none;
    }

    @media (max-width: 768px) {
      line-height: 1.2em;
      padding: 1px 5px;
    }
  }

  &__jackpot-value {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }

  &__jackpot-with-game-name {
    bottom: 40px;
  }
}
