.root {
  height: 165px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.walletheader {
  opacity: 0.35;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  text-align: center;
  color: #fff;
  display: block;
  text-transform: uppercase;
}

.walletBalance {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: #fff;
  display: block;
}

.walletButtonsInGame {
  padding: 7px 24px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: center;
  z-index: 1;
}

.transactionBtn {
  opacity: 0.88;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: center;
  color: #fff;
  text-decoration: underline;
  text-transform: uppercase;
}

.promoAreaHeader {
  display: block;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  text-align: center;
  color: #fff;
  opacity: 0.35;
  margin-bottom: 10px;
}

.rightBorder {
  border-right: solid;
  border-color: rgb(255, 255, 255, 0.21);
  border-right-width: 1px;
}

.pendingWithdrawals {
  height: 36px;
  border-radius: 4px;
  border: solid 1px #fdce07;
  margin-top: 1.25rem;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  color: #ffdc06;
}

.pendingIcons {
  z-index: 1;
  width: 23px;
  height: 23px;
  top: calc(50% - 11px);
  position: absolute;
}

.clockIcon {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  path:first-of-type {
    display: none;
  }
}

.arrowIcon {
  z-index: 2;
  transform: rotate(45deg);
}
