.root {
  @apply bg-primary;
  z-index: 50;
  position: fixed;
  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    border-top: 1px solid #ffffff36;
    box-shadow: 0 -12px 10px var(--primary-shade);
  }
}

.select {
  background: var(--primary);
  color: #fff;
  border: 1px solid #ffffff47;
  border-radius: 3px;
  font-size: 18px;
  padding: 5px 15px;
  height: 48px;
  line-height: 35px;
  position: relative;
  text-align: left;
}

.select:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  line-height: 0;
  right: 10px;
  top: 12px;
  position: absolute;
  pointer-events: none;
  z-index: 3;
  top: 21px !important;
  border-color: #ffffff47 transparent transparent transparent !important;
}

.closeModule {
  background: rgb(255, 255, 255, 0.09);
}

.cardLine {
  width: 50px;
  height: 1px;
  opacity: 0.27;
  border: solid 1px #fff;
  margin: 0 auto;
}
